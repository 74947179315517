export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "create-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a transaction"])},
        "manage-beneficiaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
        "manage-cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
        "manage-markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markets"])},
        "manage-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
        "manage-programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs"])},
        "manage-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
        "primary-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
        "check-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan a card"])},
        "manage-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])}
      },
      "fr": {
        "create-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une transaction"])},
        "manage-beneficiaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant-e-s"])},
        "manage-cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
        "manage-markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerces"])},
        "manage-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
        "manage-programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmes"])},
        "manage-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
        "primary-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
        "check-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner une carte"])},
        "manage-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])}
      }
    }
  })
}
